import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { i18n } from "./plugins/i18n.js";
import "./plugins/axios";
import moment from "moment";

Vue.config.productionTip = false;

Vue.filter("formatDate", function (value) {
  if (!value) return;
  const locale = store.getters.language || "en";
  let date = moment(String(value)).locale(locale);
  return date.format("MMM Do, YYYY");
});

Vue.filter("formatEventDate", function (value) {
  if (!value) return;
  const locale = store.getters.language || "en";
  let date = moment(value, "YYYY-MM-DD").locale(locale);
  return date.format("MMM Do, YYYY");
});

export const app = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
