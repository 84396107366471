import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
// import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/donation",
  },
  {
    path: "/registration",
    name: "registration",
    component: () => import(/* webpackChunkName: "registration" */ "@/views/Registration.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/signin",
    name: "signin",
    component: () => import(/* webpackChunkName: "signin" */ "@/views/Signin.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/reset-password",
    name: "reset password",
    component: () => import(/* webpackChunkName: "reset-password" */ "@/views/ResetPassword.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import(/* webpackChunkName: "profile" */ "@/views/Profile.vue"),
  },
  {
    path: "/payments",
    name: "payments",
    component: () => import(/* webpackChunkName: "payments" */ "@/views/Payments.vue"),
  },
  {
    path: "/donation",
    name: "donation",
    component: () => import(/* webpackChunkName: "donations" */ "@/views/Donation.vue"), 
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/*",
    redirect: "/",
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth == false)) {
    next();
    return;
  }
  if (store.getters.isLoggedIn) {
    next();
    return;
  }
  next("/donation");
});

export default router;
