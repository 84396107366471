<template>
  <v-app class="white">
    <Snackbar ref="snackbar"></Snackbar>
    <Header></Header>
    <v-content>
      <router-view></router-view>
    </v-content>
    <Footer></Footer>
  </v-app>
</template>

<script>
import Bus from "@/bus";
import Snackbar from "@/components/Layout/Snackbar";
import Header from "@/components/Layout/Header";
import Footer from "@/components/Layout/Footer";

export default {
  name: "App",
  components: {
    Snackbar,
    Header,
    Footer,
  },
  created() {
    this.initialize();
  },
  data: () => ({}),
  methods: {
    initialize() {
      Bus.$on("alert", (type, message) => {
        if (type === "success") {
          this.$refs.snackbar.showSuccess(message);
        } else if (type === "warning") {
          this.$refs.snackbar.showWarning(message);
        } else if (type === "info") {
          this.$refs.snackbar.showInfo(message);
        } else {
          this.$refs.snackbar.showError(message);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.theme--light.v-application {
  background: white;
}
.content-container {
  max-width: 960px;
  margin: 0 auto !important;
  float: none !important;
}
</style>
