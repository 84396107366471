import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "../lang/en.json";
import es from "../lang/es.json";
import fr from "../lang/fr.json";
import store from "../store";

Vue.use(VueI18n);
const locale = store.getters.language;
const fallbackLocale = "en";
const messages = {
  en: en,
  es: es,
  fr: fr,
};

export const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: fallbackLocale,
  messages: messages,
});
