<template>
  <div>
    <v-container class="content-container px-0 pb-0" fluid>
      <v-row>
        <v-col cols="4" class="d-flex justify-left px-0">
          <v-img
            :src="require('@/assets/signis-logo.png')"
            alt="Signis Logo"
            max-height="70px"
            max-width="91px"
            contain
          />
        </v-col>
        <v-col cols="4" class="d-flex justify-center align-center px-0">
          <div class="sg-heading-common">{{ pageTitle }}</div>
        </v-col>
        <v-col cols="4" class="d-flex justify-center px-0">
          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <!-- <router-link to="/">
                <a class="nav-link text-uppercase">{{ $t("header.title.directorySearch") }}</a>
              </router-link> -->
              <a
                @click="changeLanguage('en')"
                class="nav-link link-spacing"
                :class="{ 'font-weight-bold': language == 'en' }"
                >EN</a
              >
              <span class="nav-link-divider"></span>
              <a
                @click="changeLanguage('es')"
                class="nav-link link-spacing"
                :class="{ 'font-weight-bold': language == 'es' }"
                >ES</a
              >
              <span class="nav-link-divider"></span>
              <a
                @click="changeLanguage('fr')"
                class="nav-link link-spacing"
                :class="{ 'font-weight-bold': language == 'fr' }"
                >FR</a
              >
              <span class="nav-link-divider"></span>
              <a
                class="nav-link link-spacing text-uppercase"
                href="http://signis.net/"
                target="_blank"
              >
                {{ $t("header.signisPortal") }}
              </a>
            </v-col>
            <v-col cols="12" class="pt-0 d-flex justify-end">
              <template v-if="isLoggedIn">
                <router-link to="/donation">
                  <a class="nav-link text-uppercase">{{ $t("header.donate") }}</a>
                </router-link>
                <span class="nav-link-divider"></span>
                <router-link to="/profile">
                  <a class="nav-link text-uppercase">{{ userName }}</a>
                </router-link>
                <span class="nav-link-divider"></span>
                <a class="nav-link link-spacing text-uppercase" @click="signOut">{{
                  $t("header.signOut")
                }}</a>
              </template>
              <template v-else>
                <router-link to="/donation">
                  <a class="nav-link text-uppercase">{{ $t("header.donate") }}</a>
                </router-link>
                <!-- <span class="nav-link-divider"></span> -->
                <!-- <router-link to="/registration">
                  <a class="nav-link text-uppercase">{{ $t("header.register") }}</a>
                </router-link>
                <span class="nav-link-divider"></span>
                <router-link to="/signin">
                  <a class="nav-link text-uppercase">{{ $t("header.signIn") }}</a>
                </router-link> -->
              </template>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="px-0" fluid>
      <div class="sg-header-divider"></div>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({}),
  computed: {
    ...mapGetters(["language"]),
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    pageTitle() {
      const defaultTitle = this.$t("header.title.registration");
      switch (this.$route.name) {
        case "home":
          return this.$t("header.title.directorySearch");
        case "profile":
          return this.$t("header.title.profile");
        case "donation":
          return this.$t("header.title.donation");
        case "signin":
          return defaultTitle;
        case "registration":
          return defaultTitle;
        default:
          return defaultTitle;
      }
    },
    userName() {
      if (!this.isLoggedIn) return "";
      const user = this.$store.getters.user;
      // TODO: get display user (first name + 1st letter of last name)
      if (!user.firstName) return this.$t("header.profile");
      return this.$store.getters.user.firstName;
    },
  },
  methods: {
    signOut() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/signin");
      });
    },
    changeLanguage(locale) {
      this.$store.commit("setLanguage", locale);
    },
  },
};
</script>

<style lang="scss" scoped>
.content-container {
  max-width: 960px;
  margin: 0 auto !important;
  float: none !important;
}
.sg-heading-common {
  color: #444;
  font-weight: 900;
  font-size: 1.75rem;
  line-height: 1.75rem;
}
.sg-header-divider {
  height: 5px;
  background-color: var(--v-primary-base);
}
a {
  text-decoration: none;
}
.nav-link {
  font-size: 0.9rem;
}
.nav-link-divider {
  &:after {
    content: "|";
  }
  margin: 0px 10px 0px 10px;
}
.link-spacing {
  margin-top: 1.7px;
}
</style>
