import { app } from "@/main.js";

export default {
  setLanguage(state, data) {
    state.language = data;
    app.$i18n.locale = data;
  },
  storeAuthentication(state, data) {
    state.user = data.user;
    state.token = data.token;
  },
  resetAuthentication(state) {
    state.user = {};
    state.token = "";
  },
};
