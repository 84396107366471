import axios from "axios";

export const logout = ({ commit }) => {
  return new Promise((resolve) => {
    commit("resetAuthentication");
    localStorage.removeItem("token");
    delete axios.defaults.headers.common["Authorization"];
    resolve();
  });
};

export default {
  logout,
};
